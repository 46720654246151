// route.js
import {createBrowserRouter, redirect} from 'react-router-dom';
import FriendList, {friendsLoader} from './pages/users/List';
import Dashboard from './components/Dashboard';
import Master, {profilerLoader} from './pages/Master';
import CategoryList, {categoriesLoader} from './pages/categories/index';
import Signin from './pages/authentication/Signin';
import Signup from './pages/authentication/Signup';
import ErrorPage from './pages/ErrorPage';
import Profile, {profileLoader1} from './pages/profile/Profile';

const router = createBrowserRouter ([
  {
    path: '/',
    element: <Master />,
    loader: profilerLoader,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: '/users',
        children: [
          {
            index: true,
            element: <FriendList />,
            loader: friendsLoader,
          },
        ],
      },
      {
        path: '/categories',
        element: <CategoryList />,
        loader: categoriesLoader,
      },
      {
        path: '/profile',
        element: <Profile />,
      },
    ],
  },
  {
    path: '/login',
    element: <Signin />,
  },
  {
    path: '/signup',
    element: <Signup />,
  },

]);

export default router;
