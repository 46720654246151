import { useContext } from "react";
import { ProfileContext } from "../store/profile-context";

export default function Dashboard() {
  const { user } = useContext(ProfileContext);

  return (
    <>
      <h1 className="text-3xl">
        Welcome <b>{user ? user.name : ""}</b>!!
      </h1>
      <p className="mt-3 text-lg">
        I am currently learning React JS and building this app. Thank you for trying it out. Cheers 🍻 !!
      </p>
    </>
  );
}
