// App.js
import React from 'react';
import {RouterProvider} from 'react-router-dom';
import router from './Route';
import ProfileContextProvider from './store/profile-context';
import 'react-loading-skeleton/dist/skeleton.css';
import 'rsuite/dist/rsuite-no-reset.min.css';

function App () {
  return (
    <ProfileContextProvider>
      <RouterProvider router={router} />
    </ProfileContextProvider>
  );
}

export default App;
