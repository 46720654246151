import { createContext, useEffect, useState } from "react";
import { fetchUserProfile, logoutUser } from "../api";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export const ProfileContext = createContext();

export default function ProfileContextProvider({ children }) {
  const [user, setUser] = useState();

  const refreshProfile = async () => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      const data = await fetchUserProfile(token);
      setUser(data);
    }
  };

  useEffect(() => {
    refreshProfile();
  }, []);

  const logout = () => {
    const token = localStorage.getItem("accessToken");
    logoutUser(token);
    localStorage.removeItem("accessToken");
    setUser(null);
    window.location.href = "/";
    toast.success("Logout Successful");
  };

  return (
    <ProfileContext.Provider value={{ user, logout, refreshProfile }}>
      {children}
    </ProfileContext.Provider>
  );
}
