import { Dropdown, Avatar, Badge, IconButton } from "rsuite";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { ProfileContext } from "../store/profile-context";

const renderToggle = (props, ref, user) => (
  <div ref={ref} {...props} className="flex items-center">
    <Badge content={1}>
      <Avatar src={user ? user.profile_picture : ""} circle />
    </Badge>
  </div>
);

export default function Nav() {
  const { user, logout } = useContext(ProfileContext);

  return (
    <div className="px-4 py-8 h-[60px] flex items-center justify-between">
      <ul className="flex gap-3">
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <a href="/">Contacts</a>
        </li>
      </ul>
      <div className="flex items-center justify-center gap-4">
        {/* <Badge content={1}>
          <IconButton appearance="subtle" icon={<MessageIcon />} size="lg" />
        </Badge> */}
        <Dropdown
          renderToggle={(props, ref) => renderToggle(props, ref, user)}
          placement="leftStart"
        >
          <Dropdown.Item as={Link} to="/profile">
            <div className="flex gap-2 items-center">
              <i className="fa fa-user"></i>
              <p>Profile</p>
            </div>
          </Dropdown.Item>
          <Dropdown.Item>
            <div className="flex gap-2 items-center">
              <i className="fas fa-sign-out-alt"></i>
              <button onClick={() => logout()}>Logout</button>
            </div>
          </Dropdown.Item>
        </Dropdown>
      </div>
    </div>
  );
}
