import { defer, useLoaderData } from "react-router-dom";
import IndexLayout from "../../layouts/IndexLayout";
import axios from "axios";

export default function List() {
  const { categories } = useLoaderData();

  let tableRows = [
    {
      title: "Title",
      data: "title",
    },
    {
      title: "Rank",
      data: "rank",
    },
  ];

  return (
    <>
      <IndexLayout
        title="Category Management"
        items={categories}
        tableRows={tableRows}
      />{" "}
    </>
  );
}

async function loadCategories() {
  try {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    const res = await axios.get(`${process.env.REACT_APP_URL}/categories`, {
      headers,
    });

    return res.data.data;
  } catch (err) {
    //
  }
}

export function categoriesLoader() {
  return defer({
    categories: loadCategories(),
  });
}
