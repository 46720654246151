import { useContext } from "react";
import { ProfileContext } from "../../store/profile-context";

export default function Profile() {
  const { user } = useContext(ProfileContext);

  return (
    <section className="flex p-4 shadow-sm space-x-4 bg-white">
      <div className="flex-1">
        <div className="text-lg font-semibold">
          Name: {user ? user.name : "N/A"}
        </div>
        <div className="text-lg font-semibold">
          Email: {user ? user.email : "N/A"}
        </div>
        <div className="text-lg font-semibold">
          Contact: {user ? user.contact_number : "N/A"}
        </div>
      </div>
      <div className="h-[300px] w-[300px] flex items-center justify-center overflow-hidden border-2 border-gray-100">
        <img
          src={user ? user.profile_picture : ""}
          alt="User Avatar"
          className="object-cover h-full w-full"
        />
      </div>
    </section>
  );
}
