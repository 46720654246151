// api.js
import axios from 'axios';
import toast from 'react-hot-toast';
const apiUrl = process.env.REACT_APP_URL;

// ProfileAPI
export const fetchUserProfile = async accessToken => {
  const url = `${apiUrl}/profile`;
  if (!accessToken) {
    toast.error ('Please login to start your session.');
    return null;
  }

  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const res = await axios.get (url, {headers});
    return res.data.data;
  } catch (err) {
    if (err.response && err.response.status === 401) {
      toast.error ('Unauthorized. Please login again.');
    } else {
      toast.error ('An error occurred while fetching your profile.');
    }
    return null;
  }
};

//LogoutAPi
export const logoutUser = async accessToken => {
  const url = `${apiUrl}/logout`;
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  try {
    await axios.post (url, {}, headers);
  } catch (err) {
    //
  }
};
